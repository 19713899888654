<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span>各平台支持采集的字段</span>
    </div>
    <div>
      <p v-for="item in supportFields" :key="item">{{ item }}</p>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "SupportFields",
  created() {
    let me = this;
    me.axios.get('/api/platform/listFields').then(res => {
      res.forEach(field => {
        me.supportFields.push(field);
      })
    })
  },
  data() {
    return {
      supportFields: []
    }
  }
}
</script>

<style scoped>

</style>