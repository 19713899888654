<template>
  <el-main>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>创建关键词任务</span>
          </div>
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="平台" prop="platform_id">
              <el-select v-model="form.platform_id" placeholder="全部" clearable style="width: 100%">
                <el-option v-for="item in options.platform" :key="item.id" :label="item.name"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键词" prop="old_password">
              <el-tag
                  :key="keyword"
                  v-for="keyword in keywords"
                  closable
                  :disable-transitions="false"
                  @close="deleteTag(keyword)">
                {{ keyword }}
              </el-tag>
              <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="newKeyword"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="addTag"
                  @blur="addTag"
              >
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加关键词</el-button>
            </el-form-item>
            <el-form-item label="说明">
              <div>单个关键词长度限制为10个字符以内，且不允许存在空格、顿号、逗号</div>
            </el-form-item>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit()">创建任务</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
      <el-col :span="12">
        <SupportFields></SupportFields>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import SupportFields from "@/components/SupportFields";

export default {
  name: "TaskEditorKeyword",
  components: {'SupportFields': SupportFields},
  created() {
    let me = this;
    me.axios.post('/api/task/getCreatableTasks', {type: 1}).then(res => {
      res.forEach(platform => {
        me.options.platform.push(platform);
      })
    })
  },
  data() {
    return {
      keywords: [],
      inputVisible: false,
      newKeyword: '',
      form: {
        platform_id: null,
        remark: ''
      },
      options: {
        platform: []
      }

    }
  },
  methods: {
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    addTag() {
      let newKeyword = this.newKeyword;
      if (newKeyword) {
        if (this.keywords.indexOf(newKeyword) != -1) {
          this.$message.error("关键词已存在");
          return;
        }
        if (newKeyword.length > 10) {
          this.$message.error("单个关键词长度不能超过10个字符");
          return;
        }
        if (newKeyword.indexOf(" ")!=-1 || newKeyword.indexOf(",")!=-1 || newKeyword.indexOf("、")!=-1 ) {
          this.$message.error("关键词中不可包含空格、顿号、逗号");
          return;
        }
        this.keywords.push(newKeyword);
      }
      this.inputVisible = false;
      this.newKeyword = '';
    },
    deleteTag(keyword) {
      this.keywords.splice(this.keywords.indexOf(keyword), 1);
    },
    onSubmit() {
      if (this.form.platform_id == null) {
        this.$message.error("请选择一个平台");
        return;
      }
      if (this.keywords.length == 0) {
        this.$message.error("请添加至少一个关键词");
        return;
      }

      let params = {};
      params['type'] = 1;
      params['platform_id'] = this.form.platform_id;
      params['remark'] = this.form.remark;
      params['keywords'] = JSON.stringify(this.keywords);
      let me = this;
      me.axios.post('/api/job/add', params).then(() => {
        me.$router.push('/home/task/list');
      });
    }
  }
}
</script>

<style scoped>
.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>